<template>
  <basic-container>
    <avue-crud
        :rules="tenantRules"
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">

      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { isValidServerName } from '@/api/sys/tenant'

export default {
  mixins: [mixin],
  data () {
    const validateServerName = (rule, value, callback) => {
      const id = this.form.id
      isValidServerName(value, id).then(res => {
        const data = res.data
        if (!data) {
          callback(new Error('域名不能重复'))
        } else {
          callback()
        }
      })
    }
    return {
      tenantRules: {
        serverName: [{ required: true, trigger: 'blur', validator: validateServerName }]
      },
      module: 'sys/tenant',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        searchMenuSpan: 150,
        indexLabel: '序号',
        column: [
          {
            label: '租户域名',
            prop: 'serverName',
            search: true,
            searchSpan: 12,
            placeholder: '请设置租户域名',
            append: '.good.com',
            rules: [{
              required: true,
              validator: validateServerName
            }]
          },
          {
            label: '租户名',
            prop: 'tenantName',
            search: true,
            placeholder: '请设置租户名',
            rules: [{
              required: true
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.sys_tenant_add,
        viewBtn: this.permission.sys_tenant_view,
        delBtn: this.permission.sys_tenant_delete,
        editBtn: this.permission.sys_tenant_edit
      }
    }
  },
  methods: {
  }
}
</script>
