<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.channel_channelcategory_delete"
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/glass/glassbusiness'
import { remove } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'glass/glassbusiness',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        tree: true,
        indexLabel: '序号',
        column: [
          {
            label: '名称',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入商家名称'
            }]
          },
          {
            label: '授权码',
            prop: 'codeNo'
          },
          {
            label: '类型',
            prop: 'type',
            type: 'select',
            dicUrl: '/sys/dict/code/business_type'
          },
          {
            label: '创建时间',
            prop: 'createTime',
            type: 'datetime',
            addDisplay: false,
            editDisplay: false,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: '父类',
            prop: 'parentId',
            dicUrl: '/glass/glassbusiness/tree',
            type: 'tree',
            hide: true,
            props: {
              value: 'id',
              label: 'name'
            }
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.glass_glassbusiness_add,
        viewBtn: this.permission.glass_glassbusiness_view,
        delBtn: this.permission.glass_glassbusiness_delete,
        editBtn: this.permission.glass_glassbusiness_edit
      }
    }
  },
  methods: {
    onLoad (page, params = {}, callback) {
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.data = data
        if (callback) {
          callback()
        }
      })
      if (this.$refs.crud) {
        this.$refs.crud.updateDic('parentId')
      }
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return remove(this.module, row.id).then(res => {
          if (res.success) {
            this.onLoad(this.page, this.search)
            this.$message({
              type: 'success',
              message: '操作成功!',
              duration: 2000
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000
            })
          }
        })
      })
    }
  }
}
</script>
