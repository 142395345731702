<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :before-open="beforeOpen"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.dept_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template
          slot-scope="{row}"
          slot="roleId">
        <el-tag>{{ row.roleName }}</el-tag>
      </template>
      <template
          slot-scope="{row}"
          slot="deptId">
        <el-tag>{{ row.deptName }}</el-tag>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'
import { getList } from '@/api/sys/dept'
import { remove } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/dept',
      form: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        tree: true,
        border: true,
        viewBtn: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '部门名称',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入部门名称'
            }]
          },
          {
            label: '部门全称',
            prop: 'fullName',
            search: true,
            rules: [{
              required: true,
              message: '请输入部门全称'
            }]
          },
          {
            label: '上级部门',
            prop: 'parentId',
            dicUrl: '/sys/dept/tree',
            type: 'tree',
            hide: true,
            props: {
              value: 'id',
              label: 'name'
            },
            rules: [{
              required: false,
              message: '请选择上级部门',
              trigger: 'click'
            }]
          },

          {
            label: '排序',
            prop: 'sort',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入排序'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            span: 24,
            hide: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_dept_add, false),
        viewBtn: this.vaildData(this.permission.sys_dept_view, false),
        delBtn: this.vaildData(this.permission.sys_dept_delete, false),
        editBtn: this.vaildData(this.permission.sys_dept_edit, false)
      }
    }
  },
  methods: {
    onLoad (page, params = {}, callback) {
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.data = data
        if (callback) {
          callback()
        }
      })
      if (this.$refs.crud) {
        this.$refs.crud.updateDic('parentId')
      }
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.module, row.id).then(res => {
            if (res.success) {
              this.onLoad(this.page, this.search)
              this.$message({
                type: 'success',
                message: '操作成功!',
                duration: 2000
              })
            } else {
              this.$message({
                type: 'error',
                message: res.msg,
                duration: 2000
              })
            }
          })
        })
    }
  }
}
</script>

<style>
</style>
