<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.glass_glassbusinessshop_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'glass/glassbusinessshop',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: '商家编号',
            prop: 'businessId',
            rules: [{
              required: true,
              message: '请输入商家编号'
            }]
          },
          {
            label: '店铺名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入店铺名称'
            }]
          },
          {
            label: '联系人',
            prop: 'userName',
            rules: [{
              required: true,
              message: '请输入联系人'
            }]
          },
          {
            label: '联系电话',
            prop: 'mobile',
            rules: [{
              required: true,
              message: '请输入联系电话'
            }]
          },
          {
            label: '地址',
            prop: 'address',
            rules: [{
              required: true,
              message: '请输入地址'
            }]
          },
          {
            label: '组合码（5位字母-唯一）',
            prop: 'codeNo',
            rules: [{
              required: true,
              message: '请输入组合码（5位字母-唯一）'
            }]
          },
          {
            label: '完整代码',
            prop: 'allCode',
            rules: [{
              required: true,
              message: '请输入完整代码'
            }]
          },
          {
            label: '状态:1授权，2解约',
            prop: 'status',
            rules: [{
              required: true,
              message: '请输入状态:1授权，2解约'
            }]
          },
          {
            label: '类型',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入类型'
            }]
          },
          {
            label: '',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: '',
            prop: 'updateTime',
            rules: [{
              required: true,
              message: '请输入'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.glass_glassbusinessshop_add,
        viewBtn: this.permission.glass_glassbusinessshop_view,
        delBtn: this.permission.glass_glassbusinessshop_delete,
        editBtn: this.permission.glass_glassbusinessshop_edit
      }
    }
  },
  methods: {
  }
}
</script>
