<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :page.sync="page"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            icon="el-icon-plus"
            v-if="permission.sys_role_add"
            @click="addRow">新增
        </el-button>
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.sys_role_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template
          slot-scope="{row}"
          slot="roleId">
        <el-tag>{{ row.roleName }}</el-tag>
      </template>
      <template
          slot-scope="{row}"
          slot="deptId">
        <el-tag>{{ row.deptName }}</el-tag>
      </template>

      <template slot-scope="{row}" slot="menu">
        <el-button
            v-if="permission.sys_role_edit"
            size="small"
            type="text"
            @click="handleRole(row)">权限
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
        title="提示"
        :visible.sync="box"
        top="5vh"
        append-to-body
        width="70%">
      <div class="el-dialog-div">
        <div v-for="(item, index) in list" :key="index" class="containerBox">
          <div style="display: flex; align-items: center; margin-bottom: 10px;">
            <p style="font-weight: bold;">{{ item.name }}</p>
            <p class="unchecked" :class="{ checked : item.checked }" @click="checkItem(item, index, '', '')"></p>
          </div>
          <div v-for="(item2, index2) in item.children" :key="index2" class="roleBox" style="text-indent:24px;">
            <div class="parent">
              <p style="color: #999999;">{{ item2.name }}</p>
              <p style="margin-top: 3px;" class="unchecked" :class="{ checked : item2.checked }" @click="checkItem(item2, index, index2, '')"></p>
            </div>
            <div class="son">
              <div class="item" v-for="(item3, index3) in item2.children" :key="index3">
                <p>{{ item3.name }}</p>
                <p class="unchecked" :class="{ checked : item3.checked }" @click="checkItem(item3, index, index2, index3)"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
          slot="footer"
          class="dialog-footer">
        <el-button @click="box = false">取 消</el-button>
        <el-button
            type="primary"
            v-if="permission.sys_role_edit"
            @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getByRole, grant } from '@/api/sys/role'
import { tree } from '@/api/sys/menu'
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/role',
      form: {},
      box: false,
      props: {
        label: 'name',
        value: 'id'
      },
      list: [],
      defaultObj: [],
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        addBtn: false,
        viewBtn: false,
        cellBtn: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '角色名称',
            prop: 'name',
            search: true,
            span: 24,
            cell: true,
            rules: [
              {
                required: true,
                message: '请输入角色名称'
              }
            ]
          },
          {
            label: '角色编码',
            prop: 'code',
            search: true,
            span: 24,
            cell: true,
            rules: [
              {
                required: true,
                message: '请输入角色编码'
              }
            ]
          }
        ]
      },
      data: [],
      defaultKeys: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_role_add, false),
        viewBtn: this.vaildData(this.permission.sys_role_view, false),
        delBtn: this.vaildData(this.permission.sys_role_delete, false),
        editBtn: this.vaildData(this.permission.sys_role_edit, false)
      }
    }
  },
  created () {
  },
  watch: {
    box: {
      handler (val) {
        if (val === false) {
          this.defaultKeys = []
        }
      },
      deep: true
    }
  },
  methods: {
    submit () {
      const menuList = this.defaultKeys.join(',')
      grant(this.form.id, menuList).then((res) => {
        this.box = false
        if (res.success) {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    handleRole (row) {
      // 修复点击修改权限，已选中项的bug
      if (!row.id) {
        this.$message({
          type: 'error',
          message: '请编辑保存角色信息!',
          duration: 2000
        })
      } else {
        this.defaultObj = []
        this.form.id = row.id
        tree(null, row.id)
          .then(res => {
            this.list = res.data
            this.updateObj(res.data)
            return getByRole(row.id)
          })
          .then(res => {
            this.defaultObj = res.data
            this.box = true
          })
      }
    },
    checkItem (val, i, j, k) {
      const parentId = val.parentId
      const category = val.category
      this.defaultKeys = []
      if (parentId === '0') {
        this.$set(this.list[i], 'checked', !this.list[i].checked)
        // 更新下面子项
        if (this.list[i].children) {
          this.list[i].children.forEach(item => {
            item.checked = this.list[i].checked
            if (item.children) {
              item.children.forEach(c => {
                c.checked = this.list[i].checked
              })
            }
          })
        }
        this.updateObj(this.list)
        return
      }
      if (category === 2) {
        // 3级
        this.$set(this.list[i].children[j].children[k], 'checked', !this.list[i].children[j].children[k].checked)
        this.updateObj(this.list)
      } else {
        // 2级
        this.$set(this.list[i].children[j], 'checked', !this.list[i].children[j].checked)
        // 更新1级和3级
        if (this.list[i].children[j].children) {
          this.list[i].children[j].children.forEach(item => {
            item.checked = this.list[i].children[j].checked
          })
        }
        // 2级存在任意有效勾选，则选中1级
        let count = 0
        this.list[i].children.forEach(item => {
          if (item.checked) {
            count++
          }
        })
        if (count > 0) {
          this.$set(this.list[i], 'checked', true)
        } else {
          this.$set(this.list[i], 'checked', false)
        }
        this.updateObj(this.list)
      }
    },
    updateObj (obj) {
      const defaultKeys = this.defaultKeys
      for (const key in obj) {
        if (key === 'checked' && obj[key] === true) {
          this.$set(this, 'defaultKeys', defaultKeys.concat(obj.id))
        }
        if (typeof obj[key] === 'object') {
          this.updateObj(obj[key])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-dialog-div {
    height: 70vh;
    overflow-x: hidden;
  }
  .containerBox {
    margin-bottom: 20px;
    p {
      display: flex;
    }
  }
  .roleBox {
    display: flex;
    .parent {
      display: flex;
      align-items: top;
      width: 15%;
      margin-bottom: 10px;
    }
    .son {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 85%;
      .item {
        display: flex;
        align-items: center;
        width: auto;
        min-width: 180px;
        padding: 0 8px;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .unchecked {
    width: 12px;
    height: 12px;
    margin-left: 8px;
    border: 1px solid #cccccc;
  }
  .checked {
    width: 12px;
    height: 12px;
    line-height: 12px;
    border: 1px solid #409eff;
    background-color: #409eff;
    position: relative;
    cursor: pointer;
  }

  .checked::before {
    position: absolute;
    content: " ";
    width: 2px;
    height: 5px;
    transform: rotate(45deg);
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    top: 1px;
    left: 4px;
  }
</style>
