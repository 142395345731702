<template>
  <div>
    <basic-container>
      <el-tabs v-model="activeName">
        <el-tab-pane label="个人信息" name="info">
          <avue-form
              :option="option"
              v-model="form"
              @submit="handleSubmit">
          </avue-form>
        </el-tab-pane>
        <el-tab-pane label="修改密码" name="password">
          <avue-form
              :option="optionPsw"
              v-model="formPsw"
              @submit="handleSubmitPsw">
          </avue-form>
        </el-tab-pane>
      </el-tabs>
    </basic-container>
  </div>
</template>

<script>
import { getUserInfo, update, updatePassword } from '@/api/sys/user'

export default {
  data () {
    return {
      activeName: 'info',
      form: {},
      formPsw: {},
      option: {
        column: [
          {
            label: '头像',
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali',
            span: 12,
            row: true,
            prop: 'avatarUrl'
          },
          {
            label: '用户昵称',
            span: 12,
            row: true,
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入用户昵称'
            }]
          },
          {
            label: '手机号',
            span: 12,
            row: true,
            prop: 'mobile'
          }
        ]
      },
      optionPsw: {
        column: [
          {
            label: '原密码',
            span: 12,
            row: true,
            type: 'password',
            prop: 'oldPassword',
            rules: [{
              required: true,
              message: '请输入原密码'
            }]
          }, {
            label: '新密码',
            span: 12,
            row: true,
            type: 'password',
            prop: 'newPassword',
            placeholder: '密码至少六位',
            rules: [{
              required: true,
              message: '请输入新密码'
            }]
          }, {
            label: '确认密码',
            span: 12,
            row: true,
            type: 'password',
            prop: 'newPassword1',
            rules: [{
              required: true,
              message: '请输入确认密码'
            }]
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    handleSubmit (form, done) {
      console.log(form)
      update(form).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '修改信息成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }, error => {
        window.console.log(error)
        done()
      })
    },
    handleSubmitPsw (form, done) {
      updatePassword(form.oldPassword, form.newPassword, form.newPassword1).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '修改密码成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }, error => {
        window.console.log(error)
        done()
      })
    },
    loadData () {
      getUserInfo().then(res => {
        this.form = {
          id: res.data.id,
          avatarUrl: res.data.avatarUrl,
          name: res.data.name,
          mobile: res.data.mobile,
          username: res.data.username,
          deptId: res.data.deptId
        }
      })
    }
  }
}
</script>

<style>
</style>
