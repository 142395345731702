<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.glass_glasscoderecode_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'glass/glasscoderecode',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '防伪码',
            prop: 'allCode',
            rules: [{
              required: true,
              message: '请输入防伪码'
            }]
          },
          {
            label: '查询用户',
            prop: 'userId',
            dicUrl: 'app/user/all',
            props: {
              value: 'id',
              label: 'mobile'
            }
          },
          {
            label: '查询时间',
            prop: 'createTime',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.glass_glasscoderecode_add,
        viewBtn: this.permission.glass_glasscoderecode_view,
        delBtn: this.permission.glass_glasscoderecode_delete,
        editBtn: this.permission.glass_glasscoderecode_edit
      }
    }
  },
  methods: {
  }
}
</script>
