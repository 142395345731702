<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.glass_glassoptometry_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'glass/glassoptometry',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '用户',
            prop: 'userId',
            dicUrl: 'app/user/all',
            props: {
              value: 'id',
              label: 'mobile'
            }
          },
          {
            label: '左眼度数',
            prop: 'leftNumber',
            rules: [{
              required: true,
              message: '请输入左眼度数'
            }]
          },
          {
            label: '左眼是否散散光',
            prop: 'leftAstigmatism',
            type: 'select',
            dicUrl: '/sys/dict/code/yes_no'
          },
          {
            label: '左眼散光度数',
            prop: 'leftAstigmatismNumber',
            rules: [{
              required: true,
              message: '请输入左眼散光度数'
            }]
          },
          {
            label: '左眼散光轴位',
            prop: 'leftPosition',
            rules: [{
              required: true,
              message: '请输入左眼散光轴位'
            }]
          },
          {
            label: '右眼度数',
            prop: 'rightNumber',
            rules: [{
              required: true,
              message: '请输入右眼度数'
            }]
          },
          {
            label: '右眼是否散散光',
            prop: 'rightAstigmatism',
            type: 'select',
            dicUrl: '/sys/dict/code/yes_no'
          },
          {
            label: '右眼散光度数',
            prop: 'rightAstigmatismNumber',
            rules: [{
              required: true,
              message: '请输入右眼散光度数'
            }]
          },
          {
            label: '右眼散光轴位',
            prop: 'rightPosition',
            rules: [{
              required: true,
              message: '请输入右眼散光轴位'
            }]
          },
          {
            label: '瞳距',
            prop: 'pupilDistance',
            rules: [{
              required: true,
              message: '请输入瞳距'
            }]
          },
          {
            label: '瞳高',
            prop: 'pupilHeight',
            rules: [{
              required: true,
              message: '请输入瞳高'
            }]
          },
          {
            label: '近眼距',
            prop: 'eyeDistance',
            rules: [{
              required: true,
              message: '请输入近眼距'
            }]
          },
          /* {
            label: '类型',
            prop: 'type',
            type: 'select',
            dicUrl: '/sys/dict/code/optometry_type'
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            dicUrl: '/sys/dict/code/optometry_status'
          },*/
          {
            label: '建档日期',
            prop: 'createTime',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: '更新日期',
            prop: 'updateTime',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.glass_glassoptometry_add,
        viewBtn: this.permission.glass_glassoptometry_view,
        delBtn: this.permission.glass_glassoptometry_delete,
        editBtn: this.permission.glass_glassoptometry_edit
      }
    }
  },
  methods: {
  }
}
</script>
