<template>
  <basic-container>
    <template>
      <div style="float:left">
        <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-select v-model="value2" clearable placeholder="请选择状态">
          <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value3" clearable placeholder="请选择单位">
          <el-option
              v-for="item in deptList"
              :key="item.id"
              :label="item.deptName"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div style="float:left">
        <el-button @click="searchCondition">查询</el-button>
      </div>
      <div style="float:left">
        <el-button @click="ExportExcel">导出Excel</el-button>
      </div>
    </template>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :page.sync="page"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @refresh-change="refreshChange"
        @date-change="dateChange"
        @on-load="onLoad">
      <template slot-scope="scope" slot="menu">
        <el-button
            type="text"
            size="small"
            v-if="permission.work_done_detail"
            class="none-border"
            @click.stop="handleDetail(scope.row)">详情
        </el-button>
        <el-button
            type="text"
            size="small"
            v-if="permission.work_done_follow"
            class="none-border"
            @click.stop="handleImage(scope.row,scope.index)">跟踪
        </el-button>
      </template>
      <template slot-scope="{row}" slot="processDefinitionVersion">
        <el-tag>v{{ row.processDefinitionVersion }}</el-tag>
      </template>
    </avue-crud>
    <el-dialog
        title="流程图"
        append-to-body
        :visible.sync="flowBox"
        :fullscreen="true">
      <iframe
          :src=flowUrl
          width="100%"
          height="700"
          title="流程图"
          frameBorder="no"
          border="0"
          marginWidth="0"
          marginHeight="0"
          scrolling="no"
          allowTransparency="yes">
      </iframe>
      <span
          slot="footer"
          class="dialog-footer">
        <el-button @click="flowBox = false">关 闭</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { getExcelList, searchDoneList } from '@/api/work/work'
import { baseUrl, flowUrl } from '@/config'
import { formatDate } from '@/util/date'
import { getParentId } from '@/api/sys/dept'
import FileSaver from 'file-saver'

export default {
  data () {
    return {
      form: {},
      selectionId: '',
      selectionList: [],
      baseUrl: baseUrl,
      query: {
        beginDate1: '',
        endDate1: '',
        status: '',
        deptName: ''
      },
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      flowBox: false,
      flowUrl: '',
      workBox: false,
      options1: [{
        value: 'end',
        label: '完结'
      }, {
        value: 'ing',
        label: '流传中'
      }],
      option: {
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        selection: true,
        editBtn: false,
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        dialogWidth: 900,
        menuWidth: 150,
        dialogClickModal: false,
        dateBtn: false,
        column: [
          {
            label: '流程名称',
            prop: 'processDefinitionName'
            // search: true,
          },
          {
            label: '发起人',
            prop: 'startUserName'
          },
          {
            label: '当前步骤',
            prop: 'taskName'
          },
          {
            label: '当前执行人',
            prop: 'assigneeName'
          },
          {
            label: '申请时间',
            prop: 'createTime',
            width: 130
          }
        ]
      },
      deptList: [],
      data: [],
      value1: '',
      value2: '',
      value3: ''
    }
  },
  computed: {
    ...mapGetters(['permission', 'flowRoutes']),
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.businessId)
      })
      return ids.join(',')
    }
  },
  created () {
    getParentId('1').then(res => {
      this.deptList = res.data
    })
  },
  methods: {
    dateChange (date) {
      this.$message.success(date)
    },
    searchCondition () {
      let beginDate1, endDate1
      if (this.value1) {
        beginDate1 = formatDate(this.value1[0], 'yyyy-MM-dd')
        endDate1 = formatDate(this.value1[1], 'yyyy-MM-dd')
      }
      if (this.value2) {
        this.query.status = this.value2
      } else {
        this.query.status = undefined
      }
      if (this.value3) {
        this.query.deptName = this.value3
      } else {
        this.query.deptName = undefined
      }
      this.query.beginDate1 = beginDate1
      this.query.endDate1 = endDate1
      this.page.currentPage = '1'
      this.onLoad(this.page, this.query)
    },
    ExportExcel () {
      let beginDate1, endDate1
      if (this.value1) {
        beginDate1 = formatDate(this.value1[0], 'yyyy-MM-dd')
        endDate1 = formatDate(this.value1[1], 'yyyy-MM-dd')
      }
      if (this.value2) {
        this.query.status = this.value2
      } else {
        this.query.status = undefined
      }
      if (this.value3) {
        this.query.deptName = this.value3
      } else {
        this.query.deptName = undefined
      }
      this.query.beginDate1 = beginDate1
      this.query.endDate1 = endDate1
      this.page.currentPage = '1'
      getExcelList(this.query).then((response) => {
        FileSaver.saveAs(response, '事务列表.xls')
      }).catch((response) => {
        console.error('Could not Download the Excel report from the backend.', response)
      })
    },
    searchReset () {
      this.query = {}
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.query = params
      this.page.currentPage = 1
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    selectionClear () {
      this.selectionList = []
      this.$refs.crud.toggleSelection()
    },
    handleDetail (row) {
      this.$router.push({ path: `/work/process/${row.processDefinitionKey}/detail/${row.processInstanceId}/${row.businessId}` })
    },
    handleImage (row) {
      this.flowUrl = `${flowUrl}${row.processInstanceId}&v=` + new Date().getTime()
      this.flowBox = true
    },
    currentChange (currentPage) {
      this.page.currentPage = currentPage
    },
    sizeChange (pageSize) {
      this.page.pageSize = pageSize
    },
    refreshChange () {
      this.onLoad(this.page, this.query)
    },
    onLoad (page, params) {
      // const query = {
      //   ...this.query,
      //   category: (params.category) ? flowCategory(params.category) : null
      // };
      // this.loading = true;
      searchDoneList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        this.loading = false
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].processDefinitionName = this.data[i].processDefinitionName + '<br>' + this.data[i].deptName + '<br>' + this.data[i].title
        }
        // this.selectionClear();
      })
    }
  }
}
</script>

<style>
.none-border {
  border: 0;
  background-color: transparent !important;
}
</style>
