<template>
  <basic-container>
    <avue-crud
        :rules="userRules"
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        :page.sync="page"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.sys_user_delete"
            @click="handleDelete">删 除
        </el-button>
        <el-button
            type="primary"
            size="small"
            v-if="permission.sys_user_reset"
            icon="el-icon-refresh"
            @click="handleReset">密码重置
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { isValidUsername, resetPassword } from '@/api/sys/user'
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    const validateUsername = (rule, value, callback) => {
      const id = this.form.id
      isValidUsername(value, id).then(res => {
        const data = res.data
        if (!data) {
          callback(new Error('用户名不能重复'))
        } else {
          callback()
        }
      })
    }
    return {
      userRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }]
      },
      module: 'sys/user',
      form: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      init: {
        roleTree: [],
        deptTree: []
      },
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        viewBtn: true,
        dialogHeight: 450,
        searchMenuSpan: 5,
        column: [
          {
            label: '用户名',
            prop: 'username',
            search: true,
            rules: [{
              required: true,
              validator: validateUsername
            }]
          },

          {
            label: '密码',
            prop: 'password',
            hide: true,
            viewDisplay: false,
            placeholder: '密码至少六位，默认123123,'
          },
          {
            label: '用户昵称',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入用户昵称'
            }]
          },
          {
            label: '所属角色',
            prop: 'roleIdList',
            multiple: true,
            search: true,
            type: 'select',
            dicUrl: '/sys/role/dict',
            rules: [{
              required: true,
              message: '请选择所属角色',
              trigger: 'click'
            }]
          },
          {
            label: '所属部门',
            prop: 'deptId',
            type: 'tree',
            dicUrl: '/sys/dept/tree',
            dataType: 'string',
            search: true,
            props: {
              value: 'id',
              label: 'name'
            },
            rules: [{
              required: true,
              message: '请选择所属部门',
              trigger: 'click'
            }]

          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_user_add, false),
        viewBtn: this.vaildData(this.permission.sys_user_view, false),
        delBtn: this.vaildData(this.permission.sys_user_delete, false),
        editBtn: this.vaildData(this.permission.sys_user_edit, false)
      }
    }
  },
  methods: {
    handleReset () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择账号密码重置为123123?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return resetPassword(this.ids)
      }).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.$refs.crud.toggleSelection()
      })
    }
  }
}
</script>

<style>
</style>
