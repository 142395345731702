<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.glass_glasssuggest_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button size="small" type="text" @click="getT(scope.row)">
          接手
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getTask } from '@/api/glass/glasssuggest.js'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'glass/glasssuggest',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '用户',
            prop: 'userId',
            addDisplay: false,
            editDisplay: false,
            dicUrl: 'app/user/all',
            props: {
              value: 'id',
              label: 'nickName'
            }
          },
          {
            label: '联系方式',
            prop: 'phone',
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入联系方式'
            }]
          },
          {
            label: '内容',
            prop: 'suggest',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '提交日期',
            prop: 'createTime',
            type: 'datetime',
            addDisplay: false,
            editDisplay: false,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: '状态',
            prop: 'status',
            search: true,
            type: 'select',
            dicUrl: '/sys/dict/code/suggest_status'
          },
          {
            label: '接手日期',
            prop: 'getTime',
            type: 'datetime',
            html: true,
            formatter: (val) => {
              const date1 = new Date(val.createTime)
              const date2 = new Date(val.getTime)
              const diffInMilliseconds = date2.getTime() - date1.getTime()
              const diffInMinutes = diffInMilliseconds / 60000
              if (diffInMinutes > 1440) {
                return '<b style="color:red">' + val.getTime + '</b>'
              } else {
                return val.getTime
              }
            },
            addDisplay: false,
            editDisplay: false,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: '解决方案',
            prop: 'customer',
            type: 'textarea',
            span: 24
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.glass_glasssuggest_add,
        viewBtn: this.permission.glass_glasssuggest_view,
        delBtn: this.permission.glass_glasssuggest_delete,
        editBtn: this.permission.glass_glasssuggest_edit
      }
    }
  },
  methods: {
    getT (row) {
      console.log(row)
      getTask(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
      })
    }
  }
}
</script>
